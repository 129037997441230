.react-autosuggest__container {
  width: 100%;
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  padding: 8px 10px 8px 35px !important;
  border-radius: 4px !important;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  outline: none;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  margin-top: 5px;
  width: 440px;
  right: 0;
  border: 1px solid #a0a0a0;
  background-color: #ffffff;
  border-radius: 4px;
  z-index: 100;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.react-autosuggest__section-container {
  border-top: 1px dashed #ccc;
}

.react-autosuggest__section-container--first {
  border-top: 0;
}

.react-autosuggest__section-title {
  padding: 5px 10px;
  font-size: 12px;
  color: #808080;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
}

.react-autosuggest__section-container--first .react-autosuggest__section-title:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
